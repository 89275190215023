var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"600","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticStyle:{"max-height":"80vh","display":"flex","flex-direction":"column"}},[_c('v-card-title',{staticClass:"headline lighten-2 d-flex flex-column pa-0"},[_c('div',{staticClass:"d-flex flex-row event-title"},[_vm._v(" Create an event "),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.onCloseDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider',{staticStyle:{"width":"100%"}})],1),_c('div',{ref:"dialogContent",staticClass:"dialog-content"},[_c('v-card-text',{staticClass:"d-flex flex-row align-center"},[_c('editable-avatar',{attrs:{"image":_vm.profile.thumbnail ? _vm.profile.thumbnail : _vm.profile.image,"editable":false,"size":50}}),_c('span',{staticClass:"font-20 app-medium-font primary-color pl-3"},[_vm._v(" "+_vm._s(_vm.profile.name ? _vm.profile.name : _vm.profile.first_name + " " + _vm.profile.last_name)+" ")])],1),_c('v-card-text',[_c('label',{attrs:{"for":""}},[_vm._v("Select the post type?")]),(_vm.profile.type === 'Agency' && _vm.profile.status === 'Available')?_c('v-radio-group',{attrs:{"row":"","required":""},model:{value:(_vm.selectedPostType),callback:function ($$v) {_vm.selectedPostType=$$v},expression:"selectedPostType"}},[(_vm.showRadios)?_c('v-radio',{attrs:{"label":"Competition","value":"Competition"}}):_vm._e(),_c('v-radio',{attrs:{"label":"Individual","value":"Individual"}})],1):_vm._e(),(
            (_vm.profile.type === 'Volunteer' || _vm.profile.type === 'Company') &&
            _vm.profile.status === 'Available'
          )?_c('v-radio-group',{attrs:{"row":"","required":""},model:{value:(_vm.selectedPostType),callback:function ($$v) {_vm.selectedPostType=$$v},expression:"selectedPostType"}},[(_vm.volntrRadios)?_c('v-radio',{attrs:{"label":"Competition","value":"Competition"}}):_vm._e(),_c('v-radio',{attrs:{"label":"Individual","value":"Individual"}})],1):_vm._e()],1),_c('v-card-text',[_c('v-textarea',{ref:"textarea",attrs:{"rows":"4","auto-grow":"","label":"What do you want to talk about?","solo":"","flat":""},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1),(_vm.selectedFiles.length > 1)?_c('span',{staticClass:"files-text"},[_vm._v("Drag and drop to rearrange the files")]):_vm._e(),_c('v-card-text',[_c('draggable',{staticClass:"drag-container",attrs:{"group":"files"},on:{"end":_vm.onDragEnd},model:{value:(_vm.selectedFiles),callback:function ($$v) {_vm.selectedFiles=$$v},expression:"selectedFiles"}},_vm._l((_vm.selectedFiles),function(file){return _c('div',{key:file.url,staticClass:"attachment px-5"},[(file.type == 'image')?_c('v-img',{staticClass:"mx-5",attrs:{"src":file.url,"alt":"Image"}}):(file.type == 'video')?_c('vue-plyr',{ref:"plyr",refInFor:true,attrs:{"options":{
                autoplay: false,
                muted: true,
              }}},[_c('video',{attrs:{"controls":"","crossorigin":"","playsinline":""}},[_c('source',{attrs:{"src":file.url,"type":"video/mp4"}})])]):_vm._e(),_c('v-btn',{staticClass:"remove_button",attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.onRemoveAttachment(file)}}},[_c('v-icon',{attrs:{"size":"16"},domProps:{"textContent":_vm._s('mdi-close')}})],1)],1)}),0)],1),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file","accept":"image/*,video/*","multiple":""},domProps:{"value":_vm.files},on:{"change":_vm.onFileChanged}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","loading":_vm.imageLoading},on:{"click":_vm.onMediaPick}},[_c('div',[_c('v-img',{attrs:{"src":require('@/assets/svg/create-image-event.svg'),"width":"30","height":"20","contain":""}})],1)]),_c('v-menu',{attrs:{"bottom":"","offset-y":"","origin":"bottom bottom","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on: menu, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"text":""}},'v-btn',attrs,false),{ ...menu }),[_c('v-icon',{attrs:{"color":"#0A5B8A"},domProps:{"textContent":_vm._s('mdi-emoticon-happy-outline')}})],1)]}}]),model:{value:(_vm.emojiKeyboard),callback:function ($$v) {_vm.emojiKeyboard=$$v},expression:"emojiKeyboard"}},[_c('v-card',[_c('VEmojiPicker',{on:{"select":_vm.selectEmoji}})],1)],1),_c('v-spacer'),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"#0A5B8A","loading":_vm.loading,"disabled":(!_vm.text && !_vm.selectedFiles.length && !_vm.selectedPostType) ||
          !_vm.selectedPostType,"dark":""},on:{"click":_vm.onPost}},[_vm._v(" Post ")])],1),_c('v-snackbar',{model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }