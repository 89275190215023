<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card style="max-height: 80vh; display: flex; flex-direction: column">
      <v-card-title class="headline lighten-2 d-flex flex-column pa-0">
        <div class="d-flex flex-row event-title">
          Create an event
          <v-btn icon @click="onCloseDialog"><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <v-divider style="width: 100%" />
      </v-card-title>
      <div class="dialog-content" ref="dialogContent">
        <v-card-text class="d-flex flex-row align-center">
          <editable-avatar
            :image="profile.thumbnail ? profile.thumbnail : profile.image"
            :editable="false"
            :size="50"
          />
          <span class="font-20 app-medium-font primary-color pl-3">
            {{
              profile.name
                ? profile.name
                : profile.first_name + " " + profile.last_name
            }}
          </span>
        </v-card-text>
        <v-card-text>
          <label for="">Select the post type?</label>
          <v-radio-group
            v-model="selectedPostType"
            row
            v-if="profile.type === 'Agency' && profile.status === 'Available'"
            required
          >
            <v-radio
              label="Competition"
              value="Competition"
              v-if="showRadios"
            ></v-radio>
            <v-radio label="Individual" value="Individual"></v-radio>
          </v-radio-group>
          <v-radio-group
            v-model="selectedPostType"
            row
            v-if="
              (profile.type === 'Volunteer' || profile.type === 'Company') &&
              profile.status === 'Available'
            "
            required
          >
            <v-radio
              label="Competition"
              value="Competition"
              v-if="volntrRadios"
            ></v-radio>
            <v-radio label="Individual" value="Individual"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-text>
          <v-textarea
            rows="4"
            auto-grow
            label="What do you want to talk about?"
            solo
            flat
            v-model="text"
            ref="textarea"
          />
        </v-card-text>
        <!-- Drag-and-Drop Section -->
      <span class="files-text" v-if="selectedFiles.length > 1">Drag and drop to rearrange the files</span>
        <v-card-text>
          <draggable
            v-model="selectedFiles"
            group="files"
            @end="onDragEnd"
            class="drag-container"
          >
            <div
              v-for="file in selectedFiles"
              :key="file.url"
              class="attachment px-5"
            >
              <v-img
                :src="file.url"
                alt="Image"
                class="mx-5"
                v-if="file.type == 'image'"
              />
              <vue-plyr
                ref="plyr"
                :options="{
                  autoplay: false,
                  muted: true,
                }"
                v-else-if="file.type == 'video'"
              >
                <video controls crossorigin playsinline>
                  <source :src="file.url" type="video/mp4" />
                </video>
              </vue-plyr>
              <v-btn
                @click="onRemoveAttachment(file)"
                class="remove_button"
                fab
                x-small
              >
                <v-icon v-text="'mdi-close'" size="16" />
              </v-btn>
            </div>
          </draggable>
        </v-card-text>
        <!-- <v-hover
          v-slot="{ hover }"
          v-for="file in selectedFiles"
          :key="file.url"
        >
          <div class="attachment px-5">
            <v-img
              :src="file.url"
              alt="Image"
              class="mx-5"
              v-if="file.type == 'image'"
            />
            <vue-plyr
              ref="plyr"
              :options="{
                autoplay: false,
                muted: true
              }"
              v-else-if="file.type == 'video'"
            >
              <video controls crossorigin playsinline>
                <source :src="file.url" type="video/mp4" />
              </video>
            </vue-plyr>

            <v-btn
              @click="onRemoveAttachment(file)"
              class="remove_button"
              fab
              x-small
              v-show="hover"
            >
              <v-icon v-text="'mdi-close'" size="16" />
            </v-btn>
          </div>
        </v-hover> -->
        <input
          ref="uploader"
          class="d-none"
          type="file"
          :value="files"
          accept="image/*,video/*"
          @change="onFileChanged"
          multiple
        />
      </div>
      <v-card-actions>
        <v-btn text @click="onMediaPick" :loading="imageLoading">
          <div>
            <v-img
              :src="require('@/assets/svg/create-image-event.svg')"
              width="30"
              height="20"
              contain
            />
          </div>
        </v-btn>
        <!-- <v-btn text @click="onVideoPick">
          <div>
            <v-img
              :src="require('@/assets/svg/create-video-event.svg')"
              width="30"
              height="20"
              contain
            />
          </div>
        </v-btn> -->
        <v-menu
          bottom
          offset-y
          origin="bottom bottom"
          :close-on-content-click="false"
          v-model="emojiKeyboard"
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-btn v-bind="attrs" v-on="{ ...menu }" text class="ml-2">
              <v-icon v-text="'mdi-emoticon-happy-outline'" color="#0A5B8A" />
            </v-btn>
          </template>
          <v-card>
            <VEmojiPicker @select="selectEmoji" />
          </v-card>
        </v-menu>

        <!-- <v-btn icon><v-icon>mdi-calendar-star</v-icon></v-btn> -->
        <v-spacer></v-spacer>
        <!-- <v-btn
          icon
          @click="onToggleFacebook"
          :color="facebook ? '#0a5b8a' : 'gray'"
        >
          <v-icon>mdi-facebook</v-icon>
        </v-btn> -->
        <!-- <v-btn
          icon
          @click="onToggleInstagram"
          :color="instagram ? '#0a5b8a' : 'gray'"
        >
          <v-icon>mdi-instagram</v-icon>
        </v-btn> -->
        <!-- <v-btn
          icon
          @click="onToggleLinkedIn"
          :color="linkedin ? '#0a5b8a' : 'gray'"
        >
          <v-icon>mdi-linkedin</v-icon>
        </v-btn> -->
        <!-- <v-btn
          icon
          @click="onToggleTwitter"
          :color="twitter ? '#0a5b8a' : 'gray'"
        >
          <v-icon>mdi-twitter</v-icon>
        </v-btn> -->
        <v-btn
          color="#0A5B8A"
          @click="onPost"
          :loading="loading"
          :disabled="
            (!text && !selectedFiles.length && !selectedPostType) ||
            !selectedPostType
          "
          class="ml-3"
          dark
        >
          Post
        </v-btn>
      </v-card-actions>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";
import draggable from "vuedraggable";

export default {
  components: { EditableAvatar, draggable },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    onCloseDialog: {
      type: Function,
    },
    initalType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      selectedFiles: [],
      files: [],
      text: "",
      emojiKeyboard: false,
      errorMessage: null,
      snackbar: false,
      facebook: false,
      instagram: false,
      linkedin: false,
      twitter: false,
      imageLoading: false,
      selectedPostType: null,
      competitons: [],
      showRadios: false,
      volntrRadios: false,
    };
  },
  methods: {
    ...mapActions({
      addEvent: "event/addEvent",
      activeCompentition: "event/getCompetitionActive",
    }),
    onMediaPick() {
      // this.onRemoveAttachment();
      setTimeout(() => {
        this.$refs.uploader.addEventListener(
          "cancel",
          () => {
            this.imageLoading = false;
          },
          { once: true }
        );
        this.$refs.uploader.click();
        this.imageLoading = true;
      }, 300);
    },
    selectEmoji(emoji) {
      this.insertText(emoji.data);
      this.makeFocus();
    },
    insertText(text) {
      const el = this.$refs.textarea.$refs.input;
      let cursorPos = el.selectionEnd;
      this.text =
        this.text.substring(0, cursorPos) +
        text +
        this.text.substring(cursorPos);
      cursorPos += text.length;
      this.$nextTick(() => el.setSelectionRange(cursorPos, cursorPos));
      // this.emojiKeyboard = false;
    },
    makeFocus() {
      this.$refs.textarea.$refs.input.focus();
    },
    async onFileChanged(e) {
      const newFilesPromises = Array.from(e.target.files).map(async (file) => {
        let duration;
        const type = this.getType(file);

        if (type === "video") {
          duration = await this.getVideoDuration(file);
        }

        return {
          type,
          file,
          url: this.getFileMediaUrl(file),
          duration,
        };
      });

      const newFiles = await Promise.all(newFilesPromises);

      // Add the new files to the existing selectedFiles array
      this.selectedFiles = [...this.selectedFiles, ...newFiles];

      this.$nextTick(() => {
        setTimeout(() => {
          this.imageLoading = false;
          this.$forceUpdate();
          const dialogContent = this.$refs.dialogContent;
          if (dialogContent) {
            dialogContent.scrollTop = dialogContent.scrollHeight;
          }
        }, 800);
      });
    },
    // onRemoveAttachment(file) {
    //   this.selectedFiles = this.selectedFiles.filter(s => s.url != file.url);
    // },
    onRemoveAttachment(file) {
      this.selectedFiles = this.selectedFiles.filter((f) => f !== file);
    },
    onDragEnd(event) {
      console.log("Drag ended", event);
    },
    onPost() {
      let formData = new FormData();
      let videoCount = 0;
      let imageCount = 0;
      let longVideoCount = 0;
      for (let i = 0; i < this.selectedFiles.length; i++) {
        formData.append("files[]", this.selectedFiles[i].file);
        if (this.selectedFiles[i].type == "image") imageCount++;
        else if (this.selectedFiles[i].type == "video") videoCount++;
        if (
          this.selectedFiles[i].type == "video" &&
          this.selectedFiles[i].duration < 3 &&
          this.instagram
        ) {
          this.showErrorMessage(
            "For Instagram, the video must be at least 3 seconds long."
          );
          return;
        }
        if (
          this.instagram &&
          this.selectedFiles[i].type == "video" &&
          this.selectedFiles[i].duration >= 60
        ) {
          longVideoCount++;
        }
      }
      if (this.text) {
        if (this.twitter) {
          if (this.text.length >= 280) {
            this.showErrorMessage(
              "You cannot post text with more than 280 characters on Twitter."
            );
            return;
          }
        }
        formData.append("text", this.text.trim());
      }
      if (this.facebook) formData.append("facebook", "true");
      if (this.instagram) {
        if (longVideoCount && videoCount > 1) {
          this.showErrorMessage(
            "For Instagram, if a video is longer than one minute, you can only post one video at a time."
          );
          return;
        }
        if (!imageCount && !videoCount) {
          this.showErrorMessage(
            "Instagram does not support posts with text only."
          );
          return;
        }
        formData.append("instagram", "true");
      }
      if (this.linkedin) {
        if (videoCount && imageCount) {
          this.showErrorMessage(
            "You cannot post videos and images simultaneously on LinkedIn. Please remove some media or deselect the LinkedIn option."
          );
          return;
        } else if (videoCount > 1) {
          this.showErrorMessage(
            "You cannot post multiple videos at once on LinkedIn. Please remove the extra videos or deselect the LinkedIn option."
          );
          return;
        }
        formData.append("linkedin", "true");
      }
      if (this.twitter) {
        if (videoCount && imageCount) {
          this.showErrorMessage(
            "You can post 1 video or up to 4 photos to Twitter."
          );
          return;
        }
        if (videoCount > 1) {
          this.showErrorMessage(
            "You can post 1 video or up to 4 photos to Twitter."
          );
          return;
        }
        if (imageCount > 4) {
          this.showErrorMessage(
            "You can post 1 video or up to 4 photos to Twitter."
          );
          return;
        }
        formData.append("twitter", "true");
      }
      this.loading = true;
      // formData.category = this.selectedPostType;
      formData.append("category", this.selectedPostType);
      this.addEvent(formData)
        .then(() => {
          this.loading = false;
          this.selectedFiles = [];
          this.selectedPostType = null;
          this.text = "";
          this.onCloseDialog();
        })
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    loadCompetitionStatus() {
      if (this.profile.type === "Agency") {
        this.activeCompentition()
          .then((res) => {
            this.loading = false;
            this.showRadios = res.some((item) => {
              return item.competition_status == "Ongoing";
            });
          })
          .catch((error) => {
            this.loading = false;
            if (error.response == undefined) {
              //console.log(error);
            } else {
              this.showErrorMessage(error.response.data.message);
            }
          });
      } else if (this.profile.type === "Volunteer") {
        this.activeCompentition().then((res) => {
          this.loading = false;
          this.volntrRadios = res.some((item) => {
            return (
              this.profile._id === item.member.user_id &&
              item.member.response_status == "Accepted"
            );
          });
        });
      } else if (this.profile.type === "Company") {
        this.activeCompentition().then((res) => {
          this.loading = false;
          this.volntrRadios = res.some((item) => {
            return (
              this.profile._id === item.member.company_id &&
              item.member.response_status == "Accepted"
            );
          });
        });
      }
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    getType(file) {
      const mimeType = file.type;

      if (mimeType.startsWith("image/")) {
        return "image";
      } else if (mimeType.startsWith("video/")) {
        return "video";
      } else {
        return "unknown"; // or handle other types as needed
      }
    },
    getFileMediaUrl(file) {
      return URL.createObjectURL(file);
    },
    async getVideoDuration(file) {
      return new Promise((resolve, reject) => {
        const video = document.createElement("video");
        video.preload = "metadata";

        // Create an object URL for the video file
        const url = URL.createObjectURL(file);
        video.src = url;

        // Attach an event listener to get duration when metadata is loaded
        video.onloadedmetadata = function () {
          // Clean up the object URL
          URL.revokeObjectURL(url);

          if (video.duration) {
            resolve(video.duration);
          } else {
            reject("Could not retrieve video duration.");
          }
        };

        // Handle error
        video.onerror = function () {
          // Clean up the object URL
          URL.revokeObjectURL(url);
          reject("Error loading video file.");
        };
      });
    },
    onToggleFacebook() {
      if (this.profile.facebook_access_token) {
        this.facebook = !this.facebook;
      } else {
        this.snackbar = true;
        this.errorMessage =
          "Your Facebook account is not linked. Please go to Settings -> Account to connect your Facebook account, then try again.";
      }
    },
    onToggleInstagram() {
      if (this.profile.instagram_access_token) {
        this.instagram = !this.instagram;
      } else {
        this.snackbar = true;
        this.errorMessage =
          "Your Instagram account is not linked. Please go to Settings -> Account to connect your Instagram account, then try again.";
      }
    },
    onToggleLinkedIn() {
      if (this.profile.linkedin_access_token) {
        this.linkedin = !this.linkedin;
      } else {
        this.snackbar = true;
        this.errorMessage =
          "Your LinkedIn account is not linked. Please go to Settings -> Account to connect your LinkedIn account, then try again.";
      }
    },
    onToggleTwitter() {
      if (this.profile.twitter_access_token) {
        this.twitter = !this.twitter;
      } else {
        this.snackbar = true;
        this.errorMessage =
          "Your Twitter account is not linked. Please go to Settings -> Account to connect your Twitter account, then try again.";
      }
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
  },
  watch: {
    initalType(newValue) {
      if (newValue == "media") {
        this.onMediaPick();
      }
    },
  },
  mounted() {
    this.loadCompetitionStatus();
  },
};
</script>
<style scoped>
.attachment {
  overflow: visible;
  position: relative;
  cursor: pointer;
  min-height: 200px;
  margin: 10px 0px;
}
.remove_button {
  position: absolute;
  top: 10px;
  right: 30px;
  z-index: 10;
}
.event-title {
  justify-content: space-between;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 10px;
  padding-right: 24px;
  padding-left: 24px;
}
div :deep(.v-dialog) {
  max-height: 80%;
}
.dialog-content {
  flex-grow: 1;
  overflow-y: auto;
  scroll-behavior: smooth;
}
div :deep(::-webkit-scrollbar) {
  width: 5px;
}

/* Track */
div :deep(::-webkit-scrollbar-track) {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
div :deep(::-webkit-scrollbar-thumb) {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
div :deep(::-webkit-scrollbar-thumb):hover {
  background: #f0a000;
}
.remove_button {
  top: -13px !important;
}
.files-text{
  font-size: 15px;
  color: #0A5B8A;
  margin-left: 25px;
}
</style>
