<template>
  <div class="left-section">
    <v-card class="d-flex flex-column my-5" v-if="agency">
      <v-card-title>
        <span v-text="agency.name" style="color: #472583" />
      </v-card-title>
      <v-card-text class="d-flex flex-wrap">
        <site-icon
          :name="icon"
          type="Cause"
          v-for="(icon, key) in agency.causes"
          :key="key"
          :size="40"
          class="mr-3 mb-3"
        />
      </v-card-text>
      <v-card-subtitle class="mt-0">
        <span class="subtitle">Who we are</span>
      </v-card-subtitle>
      <v-card-text>
        <span v-text="agency.who_we_are" />
      </v-card-text>
      <v-card-subtitle>
        <span class="subtitle">What we do</span>
      </v-card-subtitle>
      <v-card-text>
        <span v-text="agency.what_we_do" />
      </v-card-text>
      <v-card-subtitle>
        <span class="subtitle">Location</span>
      </v-card-subtitle>
      <v-card-text>
        <div class="ma-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a"
                >mdi-map-marker</v-icon
              >
            </template>
            <span>Agency Address</span>
          </v-tooltip>
          <span>
            {{
              `${agency.address},
            ${agency.city}, ${agency.state} ${agency.zip}`
            }}
          </span>
        </div>
        <div class="ma-2" v-if="agency.address2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a"
                >mdi-map-marker</v-icon
              >
            </template>
            <span>Agency Address</span>
          </v-tooltip>
          <span>
            {{
              `${agency.address2},
            ${agency.city}, ${agency.state} ${agency.zip}`
            }}
          </span>
        </div>
      </v-card-text>
      <v-card-subtitle>
        <span class="subtitle">Contacts</span>
      </v-card-subtitle>
      <v-card-text>
        <div class="ma-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a"
                >mdi-phone-classic</v-icon
              >
            </template>
            <span>Agency Phone</span>
          </v-tooltip>
          {{ formatPhoneNumber(agency.phone) }}
        </div>
        <div class="ma-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a"
                >mdi-email</v-icon
              >
            </template>
            <span>Agency Email</span>
          </v-tooltip>
          {{ agency.email }}
        </div>
        <div class="ma-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a"
                >mdi-account-box</v-icon
              >
            </template>
            <span>Agency Contact</span>
          </v-tooltip>
          {{ agency.contact_person }}
        </div>
        <div class="ma-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a"
                >mdi-account-box</v-icon
              >
            </template>
            <span>Agency Contact Title</span>
          </v-tooltip>
          {{ agency.contact_title }}
        </div>
      </v-card-text>
      <v-card-subtitle>
        <span class="subtitle">Links</span>
      </v-card-subtitle>
      <v-card-text>
        <div class="ma-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a"
                >mdi-link-variant</v-icon
              >
            </template>
            <span>Agency Website</span>
          </v-tooltip>
          <!-- <a :href="agency.website" target="_blank">Website</a> -->
            <a :href="agency.website" target="_blank">{{ getHostname(agency.website) }}</a>
        </div>
        <div class="ma-2" v-if="agency.facebook">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a"
                >mdi-facebook</v-icon
              >
            </template>
            <span>Facebook</span>
          </v-tooltip>
          <a :href="agency.facebook" target="_blank">Facebook</a>
        </div>
        <div class="ma-2" v-if="agency.twitter">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a"
                >mdi-twitter</v-icon
              >
            </template>
            <span>Twitter</span>
          </v-tooltip>
          <a :href="agency.twitter" target="_blank">Twitter</a>
        </div>
      </v-card-text>
      <v-card-subtitle>
        <span class="subtitle" v-if="agency.photos.length != 0">Photos</span>
      </v-card-subtitle>
      <v-card-text>
        <agency-photo-viewer :agency_id="agency._id" />
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AgencyPhotoViewer from "@/components/agency/AgencyPhotoViewer.vue";
import SiteIcon from "@/components/common/SiteIcon.vue";
export default {
  components: { AgencyPhotoViewer, SiteIcon },
  computed: {
    ...mapGetters({
      profile: "auth/getProfile",
      profileType: "auth/getType",
      agency: "agency/getAgency"
    })
  },
  methods: {
    getHostname(url) {
      try {
        return new URL(url).hostname;
      } catch (e) {
        return url;
      }
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    }
  }
};
</script>
<style scoped>
.left-section {
  top: 70px;
  max-width: 360px;
  min-width: 360px;
  width: 360px;
  padding-left: 30px;
}
.agency-title {
  font-size: 28px;
  color: #0a5b8a;
  font-weight: 500;
}
.subtitle {
  font-size: 16px;
  font-weight: 500;
  color: #0a5b8a;
}
</style>
